import { Component, Injector, NgZone, ViewChild, ElementRef, HostListener, Inject, Renderer2, ChangeDetectorRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ActivatedRoute, Router } from '@angular/router';
import { FundraisersServiceProxy, GetFundraiserUserInfo } from '../../../../shared/service-proxies/service-proxies';
import { HelpReachGoalInfo } from '../help-reach-goal/help-reach-goal.component';
import { GoalNumbers } from '../fundraiser-home/fundraiser-home.component';
import { CompleteOrderInfo, OrderDetailPlusProductAndPackageViewModels } from '../checkout/checkout.component';
import { NgModel } from '@angular/forms';
import { InputMask } from 'primeng/inputmask';
import { DateTime } from 'luxon';
import { AppConsts } from '@shared/AppConsts';
import { DOCUMENT } from '@angular/common';
import { OrderConfirmationComponent } from '../order-confirmation/order-confirmation.component';

@Component({
    templateUrl: './launchathon-fundraiser-home.component.html',
    styleUrls: ['./launchathon-fundraiser-home.component.less'],
    animations: [appModuleAnimation()]
})
export class LaunchathonFundraiserHomeComponent extends AppComponentBase {
    @ViewChild('selectDonationArea', { static: false }) donationOptionsRow: ElementRef;
    @ViewChild('donationAmountInput', { static: false }) donationAmountInput: NgModel;
    @ViewChild('orderConfirmation') orderConfirmationComponent: OrderConfirmationComponent;

    idForFundraiserInfo: string;  // fundraiserUser or fundraiser ID
    fundraiserUserId: number;
    fundraiserStudentContactSlug: string;
    automatedMessageType: string;
    tenantId: number;

    daysLeft: string;
    get dayVsDays(): string {
        let result = 'Days';
        if (this.daysLeft == '1') {
            result = 'Day'
        }
        return result;
    }
    donationAmount: number;
    _customDonationAmount: string;
    get customDonationAmount(): number {
        if (!this._customDonationAmount){
            return 0;
        }
        return +(this._customDonationAmount.replace('$', ''));
    }
    donationOptions = [500, 250, 150, 100, 50];

    appListing: string[] = ['facebook', 'twitter', 'email'];
    appListingSms: string[] = ['sms'];
    shareTitle: string;
    shareDescription: string;
    shareUrl: string;
    shareUrlSms: string;

    teamMemberId: number;

    helpReachGoalInfo: HelpReachGoalInfo;

    isApplePayEnabledForFundraiser: boolean = false;
    isISponsorEnabledForFundraiser: boolean = false;

    _effectiveStudentGoal: number;
    get effectiveStudentGoal(): number {
        return this._effectiveStudentGoal ?? (this._effectiveStudentGoal =  this.getEffectiveGoal(this.studentRaised, this.studentGoal, AppConsts.studentStretchIncrementDollars));
    }

    _effectiveTeamGoal: number;
    get effectiveTeamGoal(): number {
        return this._effectiveTeamGoal ?? (this._effectiveTeamGoal =  this.getEffectiveGoal(this.teamRaised, this.teamGoal, AppConsts.teamStretchIncrementDollars));
    }

    getEffectiveGoal(raised: number, goal: number, stretchIncrement: number): number {
        if (!this.fundraiserUserInfo)
            return null;

        // `goal` is the `goal`
        //  UNLESS ...
        //      `goal` has been exceeded 
        //          OR
        //      `viewSample` is true
        //  THEN ...
        //      `goal` is the next `stretchIncrement` past the goal
        if (this.viewSample || raised < goal)
            return goal;

        return (Math.trunc((raised - goal) / stretchIncrement) + 1) 
            * stretchIncrement 
            + goal;
    }

    fundraiserUserInfo: GetFundraiserUserInfo;
    get teamGoal() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.teamGoal.toFixed();
    }
    get teamGoalPercentEffective() {
        if (!this.fundraiserUserInfo) return '0';
        return this.teamRaised < this.effectiveTeamGoal ? (this.teamRaised / this.effectiveTeamGoal * 100).toString() + '%' : '100%';
    }
    get teamGoalPercentActual() {
        if (!this.fundraiserUserInfo) return '0';
        return this.teamRaised < this.teamGoal ? (this.teamRaised / this.teamGoal * 100).toString() + '%' : '100%';
    }
    get studentGoalPercentEffective() {
        if (!this.fundraiserUserInfo) return '0';
        return this.studentRaised < this.effectiveStudentGoal ? (this.studentRaised / this.effectiveStudentGoal * 100).toString() + '%' : '100%';
    }
    get studentGoalPercentActual() {
        if (!this.fundraiserUserInfo) return '0';
        return this.studentRaised < this.studentGoal ? (this.studentRaised / this.studentGoal * 100).toString() + '%' : '100%';
    }
    get studentGoalRemaining() {
        if (!this.fundraiserUserInfo) return 0;
        return this.effectiveStudentGoal > this.studentRaised ? this.effectiveStudentGoal - this.studentRaised : 0;
    }
    get teamRaised() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.teamRaised.toFixed();
    }
    get studentRaised() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.studentRaised.toFixed();
    }
    get studentGoal() {
        if (!this.fundraiserUserInfo) return 0;
        return +this.fundraiserUserInfo.studentGoal.toFixed();
    }

    customerEmailAddress: string;

    slugParam: string;
    orderDetailId: number;
    constructor(
        injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private _fundraisersServiceProxy: FundraisersServiceProxy,
        public _zone: NgZone,
        private _router: Router,
        private _renderer2: Renderer2,
        private cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: Document
    ) {
        super(injector);

        this._activatedRoute.params.subscribe(params => {
            this.orderDetailId = params.orderId;

            this.fundraiserUserId = params.fundraiserUserId;
            this.slugParam = params.slug;

            this._activatedRoute.queryParams.subscribe(qparams => {
                this.fundraiserStudentContactSlug = qparams['fscSlug'];
                this.automatedMessageType = qparams['automatedMessageType'];

                this.getFundraiserUserInfo();
            });
        });
    }

    viewSample: boolean;
    ngOnInit(): void {
        this.viewSample = window.location.pathname.includes('viewSample');
    }

    getFundraiserUserInfo() {
        this._fundraisersServiceProxy.getFundraiserUserInfo(this.fundraiserUserId, this.slugParam, this.orderDetailId).subscribe(result => {
            this.fundraiserUserInfo = result;
            this.tenantId = this.fundraiserUserInfo.tenantId;
            this.isApplePayEnabledForFundraiser = this.fundraiserUserInfo.enableApplePay;
            this.isISponsorEnabledForFundraiser = this.fundraiserUserInfo.enableISponsor;

            if (this.fundraiserUserId && this.appSession.user?.id == this.fundraiserUserInfo.studentId) {
                this.shareDescription = this.getFilledInShareMessage(this.fundraiserUserInfo.smsStudentShareMessage ?
                    this.fundraiserUserInfo.smsStudentShareMessage : this.l('DefaultStudentShareMessage'));
            }
            else {
                this.shareDescription = this.getFilledInShareMessage(this.fundraiserUserInfo.smsShareMessage ?
                    this.fundraiserUserInfo.smsShareMessage : this.l('OrderHelpFundraiser'));
            }

            this.shareTitle = `${this.fundraiserUserInfo.organizationName} - ${this.fundraiserUserInfo.fundraiserName}`;

            this.teamMemberId = this.fundraiserUserInfo.studentId;

            this.helpReachGoalInfo = new HelpReachGoalInfo(this.fundraiserUserInfo.isFundraiserEndUtc, this.fundraiserUserInfo.fundraiserEnd);

            this.setIsFundraiserOver();
            if (this.isFundraiserOver) {
                this.insertTrackingTagScript();
            }

            if (this.fundraiserUserId) {
                this.setCarouselGoalComponents();
            }
            this.shareUrl = this.getShareLink();
            this.shareUrlSms = this.getSmsVersionOfShareUrl(this.shareUrl);

            if (this.orderDetailId) {
                this.submit();
            }
        });
    }

    insertTrackingTagScript() {
        let script = this._renderer2.createElement('script');
        script.text = `
            {
                gtag('event', 'ended_pageview', {
                    'fundraiser_ID': '` + this.fundraiserUserInfo.fundraiserId + `',
                  });
            }
        `;
        this._renderer2.appendChild(this._document.body, script);
    }

    getSecondsLeftInFundraiser(now: DateTime = null) {
        let nowUtcSeconds: number;
        let offset: number;

        if (this.fundraiserUserInfo?.fundraiserEnd == null) {
            return null;
        }
        if (now == null) {
            now = DateTime.now();
        }
        nowUtcSeconds = now.toSeconds();
        // if !isFundraiserEndUtc, assume client is in same timezone as fundraiser.
        offset = this.fundraiserUserInfo?.isFundraiserEndUtc ? 0 : now.offset * 60;
        // end date is stored in the DB with midnight (the start) of the day for time,
        // so add a day's worth of seconds (86400) to the result.
        let result = this.fundraiserUserInfo.fundraiserEnd - nowUtcSeconds - offset + 86400;
        return result;
    }
    setIsFundraiserOver(): void {
        let now = DateTime.now();
        this.isFundraiserOver = this.getSecondsLeftInFundraiser(now) < 0;
    }

    isFundraiserOver: boolean;

    getFilledInShareMessage(rawShareMessage: string): string {
        if (!this.fundraiserUserInfo) return '';
        let result = rawShareMessage.replace('{STUDENTNAME}', this.fundraiserUserInfo.studentName).replace('{ORGANIZATIONNAME}', this.fundraiserUserInfo.organizationName)
            .replace('{STUDENTGOAL}', this.effectiveStudentGoal.toString());
        return result;
    }

    reload(orderDetailPlusProductViewModels: OrderDetailPlusProductAndPackageViewModels): void {
        this.donationAmount = orderDetailPlusProductViewModels.orderDetail.donationAmount;
        this.isCheckout = false;
    }

    donationClick(donationAmount: number) {
        this.donationAmount = donationAmount;
        this._customDonationAmount = undefined;
        this.completeGoalDonationAmount = undefined;
    }
    customDonationClick() {
        this.donationAmount = undefined;
        this.completeGoalDonationAmount = undefined;
        ((this.donationAmountInput.valueAccessor as InputMask).inputViewChild as ElementRef).nativeElement.focus();
    }

    completeGoalDonationAmount: number;
    completeStudentGoal() {
        this.completeGoalDonationAmount = this.effectiveStudentGoal - this.studentRaised;
        this.donationAmount = undefined;
        this._customDonationAmount = undefined;
    }

    get selectedDonationAmount() {
        return this.donationAmount ?
            this.donationAmount : this.customDonationAmount ? this.customDonationAmount : this.completeGoalDonationAmount;
    }

    isCheckout: boolean;
    submit() {
        if (!this.orderDetailId && !this.selectedDonationAmount) {
            return;
        }
        this.isCheckout = true;
    }

    isOrderComplete: boolean = false;
    orderTransactionId: string;
    orderComplete(orderInfo: CompleteOrderInfo) {
        this.isCheckout = false;
        this.isOrderComplete = true;
        this.orderTransactionId = orderInfo.orderTransactionId;
        this.customerEmailAddress = orderInfo.customerEmailAddress;
        this.fundraiserUserInfo.teamRaised += orderInfo.orderAmount;
        if (this.studentRaised && !Number.isNaN(this.studentRaised)) {
            this.fundraiserUserInfo.studentRaised += orderInfo.orderAmount;
        }
        if (this.isISponsorEnabledForFundraiser && this.orderConfirmationComponent) {
            this.orderConfirmationComponent.isISponsorEnabled = this.isISponsorEnabledForFundraiser;
            this.orderConfirmationComponent.iSponsorPromotionComponent.customerInformation = orderInfo.isponsorInformation;
            orderInfo.isponsorInformation = null;
        } else {
            orderInfo.isponsorInformation = null;
        }
        this.cdr.detectChanges();
    }

    cancelOrder() {
        this.isCheckout = false;
        this.resetDonationSelections();
    }

    returnFromConfirmation() {
        this.isCheckout = false;
        this.isOrderComplete = false;
        this.resetDonationSelections();
        this.getFundraiserUserInfo();
    }

    resetDonationSelections() {
        this.donationAmount = undefined;
        this._customDonationAmount = undefined;
        this.completeGoalDonationAmount = undefined;
    }

    getShareLink(): string {
        let fundraiserUserId = this.fundraiserUserId ? this.fundraiserUserId : '0';
        let slug = this.fundraiserUserInfo?.slug ? this.fundraiserUserInfo.slug : '';

        let link = `${this.appRootUrl()}FundraiserHomeInfo/${fundraiserUserId}/${slug}`;

        if (window.location.href.includes('localhost')) {
            link = link.replace('FundraiserHomeInfo', 'fundraiserHomeInfo');
        }
        return link;
    }

    async navigateToProductFundraiserHomePage() {
        if (this.fundraiserUserId) {
            this._router.navigate(['productFundraiserHome', 'student', this.fundraiserUserId]);
        }
        else {
            this._router.navigate(['productFundraiserHome', 'fundraiser', this.fundraiserUserInfo.slug]);
        }
    }

    pageYoffset = 0;
    @HostListener('window:scroll', ['$event']) onScroll(event) {
        this.pageYoffset = window.pageYOffset;
    }

    scrollToDonationOptions() {
        if (this.donationOptionsRow) {
            const htmlElement = this.donationOptionsRow.nativeElement as HTMLElement;
            htmlElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }

    goalValues: GoalNumbers[] = [];
    setCarouselGoalComponents(): void {
        let goalValuesFull: GoalNumbers[] = [];
        goalValuesFull.push({
            rightTitle: this.l('MyGoalLabel'),
            rightAmount: this.isFundraiserOver ? this.studentGoal : this.effectiveStudentGoal,
            leftTitle: this.l('TotalRaisedLabel'),
            leftAmount: this.studentRaised,
            progressBarValue: this.isFundraiserOver ? this.studentGoalPercentActual : this.studentGoalPercentEffective,
            name: this.fundraiserUserInfo.studentName
        })

        if (!this.isFundraiserOver) {
            goalValuesFull.push({
                rightTitle: this.l('TeamGoalLabel'),
                rightAmount: this.effectiveTeamGoal,
                leftTitle: this.l('TotalRaisedLabel'),
                leftAmount: this.teamRaised,
                progressBarValue: this.teamGoalPercentEffective,
                name: this.l('Team')
            });
        } else {
            goalValuesFull.push({
                rightTitle: this.l('TotalRaisedLabel'),
                rightAmount: this.teamRaised,
                leftTitle: this.l('TeamGoalLabel'),
                leftAmount: this.teamGoal,
                progressBarValue: this.teamGoalPercentActual,
                name: this.l('Team')
            })
        }
        this.goalValues = goalValuesFull;
    }
}